import React from "react"
import CaseStudiesSectionStyled from "./styles/caseStudiesSectionStyles"
import Box from "./box.js"

const caseStudiesSectionHome = ({ data }) => {
  return (
    <CaseStudiesSectionStyled>
      {data.map(item => (
        <Box
          key={item.id}
          slug={item.slug}
          heading={item.acf.case_list_heading}
          description={item.acf.case_list_description}
          background={item.acf.case_small_bg}
          image={item.acf.case_logo}
          buttonColor={item.acf.case_list_button_color}
          buttonColorHover={item.acf.case_list_button_hover_color}
          buttonText={item.acf.case_list_button_text}
          url={`/case-studies/${item.slug}`}
        />
      ))}
    </CaseStudiesSectionStyled>
  )
}

export default caseStudiesSectionHome
