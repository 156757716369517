import React, { useState, useRef } from "react"
import { Helmet } from "react-helmet"
import Slider from "react-slick"
import Img from "gatsby-image"
import Button from "./button"

const SlickSlider = props => {
  const [slideIndex, setSlideIndex] = useState(0)
  const slider = useRef()

  const { hero_heading, hero_button, hero_slide } = props
  const { title, url } = hero_button

  const settings = {
    beforeChange: (current, next) => setSlideIndex(next),
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // vertical: true,
    // verticalSwiping: true,
    fade: true,
    arrows: false,
    // autoplay: true,
    // autoplaySpeed: 5000,
  }

  return (
    <>
      <Helmet defer={false}>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <div className="container">
        <div className="slider__left">
          <h1
            className="home__heading"
            dangerouslySetInnerHTML={{ __html: hero_heading }}
          ></h1>
          <div className="slider__nav">
            {hero_slide.map((nav, index) => (
              <p
                key={`slider__nav__item_${index}`}
                className={`slider__nav__item ${
                  index === slideIndex ? "active" : ""
                }`}
                onClick={() => {
                  setSlideIndex(index)
                  slider.current.slickGoTo(index)
                }}
              >
                {nav.heading}
                <span>{nav.subHeading}</span>
              </p>
            ))}
          </div>
          <Button
            text={title}
            link={url}
            color="blue"
            hover_color="transparent"
          />
        </div>
      </div>
      <div className="slider__img">
        <Slider ref={slider} {...settings}>
          {hero_slide.map((nav, index) => (
            <div className="slider__img--item" key={`slider__img__${index}`}>
              <Img fixed={nav.img.localFile.childImageSharp.fixed} />
            </div>
          ))}
        </Slider>
      </div>
    </>
  )
}

export default SlickSlider
