import styled from "styled-components"

const HomeStyled = styled.div`
  .contact {
    a {
      color: #fff;
      transition: 0.3s all;
    }
  }
  .scrolled {
    .contact {
      a {
        color: #05b7ba;
      }
    }
  }
  .hero.slider {
    position: relative;
    margin-top: -144px;
    height: 740px;
    display: flex;
    align-items: center;
    padding-top: 140px;
    @media (max-width: 1180px) {
      height: 690px;
    }
    @media (max-width: 940px) {
      height: 635px;
    }
    @media (max-width: 540px) {
      margin-top: -168px;
    }
    @media (max-width: 450px) {
      margin-top: -150px;
    }
    .container {
      @media (max-width: 940px) {
        z-index: 20;
      }
    }
    .slider__img {
      position: absolute;
      top: 0;
      bottom: -50px;
      right: 0;
      width: 50vw;
      @media (max-width: 940px) {
        width: 100vw;
      }
      * {
        height: 100%;
      }
      img {
        object-fit: cover !important;
        height: 100% !important;
        width: 100% !important;
      }
      .gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
  .slider__left {
    max-width: 45%;
    @media (max-width: 940px) {
      max-width: 100%;
      background: rgba(0, 0, 0, 0.5);
      padding: 30px;
    }
    .home__heading {
      font-size: 60px;
      color: #fff;
      line-height: 80px;
      font-weight: 700;
      padding-bottom: 40px;
      @media (max-width: 1180px) {
        font-size: 45px;
        line-height: 60px;
        padding-bottom: 30px;
      }
      @media (max-width: 680px) {
        font-size: 2rem;
        line-height: 2.5rem;
      }
      @media (max-width: 540px) {
        padding-bottom: 25px;
      }
      @media (max-width: 450px) {
        font-size: 20px;
      }
    }
    .slider__nav {
      &__item {
        cursor: pointer;
        line-height: 1;
        font-size: 30px;
        line-height: 40px;
        color: #fff;
        position: relative;
        border-left: 1px solid #05b7ba;
        padding-left: 25px;
        transition: 0.3s all;
        margin-bottom: 30px;
        @media (max-width: 1180px) {
          font-size: 25px;
          line-height: 35px;
        }
        @media (max-width: 540px) {
          font-size: 22px;
          line-height: 30px;
        }
        @media (max-width: 450px) {
          font-size: 20px;
          line-height: 25px;
        }
        &.active {
          padding-left: 50px;
        }
        /* &:last-of-type {
          margin-bottom: 0px;
        } */
        span {
          padding-top: 5px;
          font-size: 18px;
          color: #7c7c7c;
          display: block;
          line-height: 1;
          @media (max-width: 540px) {
            font-size: 16px;
          }
          @media (max-width: 450px) {
            font-size: 15px;
          }
        }
      }
    }
  }
  .home__white__box {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .home__caseStudies {
    padding-bottom: 100px;
    .caseLink {
      text-align: center;
      a {
        text-underline-position: under;
        color: #05b7ba;
        transition: 0.3s all;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
`

export default HomeStyled
