import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import SlickSlider from "../components/slider"
import HomeStyled from "../components/styles/HomeStyles"
import Builder from "../components/builder"
import CaseStudiesListHome from "../components/caseStudiesSectionHome"

const IndexPage = ({ data }) => {
  const {
    hero_heading,
    hero_button,
    hero_slide,
    builder_first,
    builder_second,
    builder_third,
    builder_fourth,
    builder_fifth,
    case_heading,
  } = data.wordpressPage.acf

  const { nodes } = data.allWordpressWpCaseStudies
  const sectionFirst = builder_first.builder[0]
  const sectionSecond = builder_second.builder[0]
  const sectionThird = builder_third.builder[0]
  const sectionFourth = builder_fourth.builder[0]
  const sectionFifth = builder_fifth.builder[0]

  const { title, canonical } = data.wordpressPage.yoast
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        {canonical ? <link rel="canonical" href={canonical} /> : "Start - Dapr"}
        {/* {data.yoast.map(meta_value => {
          return (
            <meta
              name={meta_value.name || meta_value.property}
              content={meta_value.content}
            />
          )
        })} */}
      </Helmet>
      <HomeStyled>
        <Layout>
          <section className="hero slider">
            <SlickSlider
              hero_heading={hero_heading}
              hero_button={hero_button}
              hero_slide={hero_slide}
            />
          </section>
          <section className="background__white white__box home__white__box">
            <Builder
              loyaut={sectionFirst.acf_fc_layout}
              data={sectionFirst.row}
              startPosition={sectionFirst.start_position}
            />
          </section>
          <section className="imgHeading home__imgHeading">
            <Builder
              loyaut={sectionSecond.acf_fc_layout}
              text={sectionSecond.text}
              img={sectionSecond.img}
              topBackground={sectionSecond.top_background}
              bottomBackground={sectionSecond.bottom_background}
            />
          </section>
          <section className="background__dark dark__box home__dark__box">
            <Builder
              loyaut={sectionThird.acf_fc_layout}
              data={sectionThird.row}
              startPosition={sectionThird.start_position}
            />
          </section>
          <section className="imgHeading home__imgHeading">
            <Builder
              loyaut={sectionFourth.acf_fc_layout}
              text={sectionFourth.text}
              img={sectionFourth.img}
              topBackground={sectionFourth.top_background}
              bottomBackground={sectionFourth.bottom_background}
            />
          </section>
          <section className="background__white white__box home__white__box">
            <Builder
              loyaut={sectionFifth.acf_fc_layout}
              data={sectionFifth.row}
              startPosition={sectionFifth.start_position}
            />
          </section>
          <section className="background__white caseStudies home__caseStudies">
            <div className="container">
              <h2 className="heading--2">{case_heading}</h2>
              <CaseStudiesListHome data={nodes} />
              <div className="caseLink">
                <Link to="case-studies">Zobacz wszystkie case studies</Link>
              </div>
            </div>
          </section>
        </Layout>
      </HomeStyled>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query getHomePage {
    wordpressPage(title: { eq: "home" }) {
      yoast {
        title
        opengraph_description
        opengraph_image
        opengraph_title
        metakeywords
        metadesc
        canonical
        focuskw
        linkdex
        meta_robots_adv
        meta_robots_nofollow
        meta_robots_noindex
        redirect
        twitter_image
        twitter_title
        twitter_description
      }
      acf {
        hero_heading
        hero_button {
          title
          url
          target
        }
        hero_slide {
          heading
          subHeading
          img {
            localFile {
              childImageSharp {
                fixed(width: 950, quality: 90) {
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  width
                  height
                }
              }
            }
          }
        }
        builder_first {
          builder {
            acf_fc_layout
            start_position
            row {
              button_color
              button_hover_color
              button_link
              description
              button_text
              heading
              img {
                source_url
                title
                localFile {
                  childImageSharp {
                    fixed(width: 600, quality: 90) {
                      srcSetWebp
                      srcWebp
                      srcSet
                      src
                      width
                      height
                    }
                  }
                }
              }
              subHeading
            }
          }
        }
        builder_second {
          builder {
            acf_fc_layout
            text
            top_background
            bottom_background
            img {
              source_url
              title
              localFile {
                childImageSharp {
                  fixed(width: 1360, quality: 90) {
                    srcSetWebp
                    srcWebp
                    srcSet
                    src
                    width
                    height
                  }
                }
              }
            }
          }
        }
        builder_third {
          builder {
            acf_fc_layout
            start_position
            row {
              button_color
              button_hover_color
              button_link
              button_text
              description
              heading
              subHeading
              img {
                source_url
                title
                localFile {
                  childImageSharp {
                    fixed(width: 890, quality: 90) {
                      srcSetWebp
                      srcWebp
                      srcSet
                      src
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
        builder_fourth {
          builder {
            acf_fc_layout
            text
            top_background
            bottom_background
            img {
              source_url
              title
              localFile {
                childImageSharp {
                  fixed(width: 1360, quality: 90) {
                    srcSetWebp
                    srcWebp
                    srcSet
                    src
                    width
                    height
                  }
                }
              }
            }
          }
        }

        builder_fifth {
          builder {
            acf_fc_layout
            start_position
            row {
              button_color
              button_hover_color
              button_link
              button_text
              description
              heading
              img {
                source_url
                title
                localFile {
                  childImageSharp {
                    fixed(width: 600, quality: 90) {
                      srcSetWebp
                      srcWebp
                      srcSet
                      src
                      width
                      height
                    }
                  }
                }
              }
              subHeading
            }
          }
        }

        case_heading
      }
    }
    allWordpressWpCaseStudies(limit: 3) {
      nodes {
        id
        slug
        acf {
          case_list_button_color
          case_list_button_hover_color
          case_list_button_text
          case_list_description
          case_list_heading
          case_logo {
            source_url
            title
            localFile {
              childImageSharp {
                fixed(width: 240, quality: 90) {
                  srcSetWebp
                  srcWebp
                  srcSet
                  src
                  width
                  height
                }
              }
            }
          }
          case_small_bg {
            source_url
            title
            localFile {
              childImageSharp {
                fixed(width: 420, quality: 90) {
                  srcSetWebp
                  srcWebp
                  srcSet
                  src
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`
