import styled from "styled-components"

const caseStudiesListStyled = styled.div`
  display: flex;
  @media (max-width: 940px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

export default caseStudiesListStyled
